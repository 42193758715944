import React, { FunctionComponent } from "react";
import { globalHistory, Location } from "@reach/router";
import { QueryParamProvider } from "use-query-params";
import ClickTracker from "../Analytics/ClickTracker";
import ScrollTracker from "../Analytics/ScrollTracker";

const App: FunctionComponent = ({ children }) => {
  
  return (
    <Location>
      {({ location }) => (
        <QueryParamProvider location={location} reachHistory={globalHistory}>
          <ScrollTracker></ScrollTracker>
          <ClickTracker>{children}</ClickTracker>
        </QueryParamProvider>
      )}
    </Location>
  );
};

export default App;
