import { EventType, pushEvent } from "./index";

/**
 * Clicks to start Sign Up
 */
export const event57 = (e: MouseEvent) => {
  const link = (e.target as Element).closest("[data-analytics-event57]");
  if (!link) return;

  const pageURL = typeof window !== "undefined" ? window.location.href : "";
  if (!pageURL) return;

  pushEvent({
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.SignUpstart,
      eventLabel: `${pageURL}`,
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.other
    }
  });
};
