import { EventType, pushEvent } from "./index";

/**
 * External Link
 */

export const event68 = (e: MouseEvent) => {

  if ((e.target as Element).classList.contains('external-link') || (e.target as Element).classList.contains('optInLinks')) {

  const link = (e.target as Element).closest("a");
  // not an 'a', missing 'href' or is a hash/anchor ?
  if (!link || (link && !link.href) || link.href.indexOf("#") !== -1) return;
  
    const text = link.innerText === "" ? link.getAttribute("aria-label") : link.innerText?.trim();
  
    pushEvent({
      eventInfo: {
        type: EventType.trackEvent,
        eventAction: EventType.ExternalLink,
        eventLabel: `${text} - ${link.href}`,
        eventValue: 1
      },
      category: {
        primaryCategory: EventType.custom
      }
    });
  }

};
