import { EventType, pushEvent } from "./index";

/**
 * Link Click
 */
export const event2 = (e: MouseEvent) => {
  const link = (e.target as Element).closest("a");
  // not an 'a', missing 'href' or is a hash/anchor ?
  if (!link || (link && !link.href) || link.href.indexOf("#") !== -1) return;

  const text = link.textContent === "" ? link.getAttribute("aria-label") : link.textContent?.trim();

  pushEvent({
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.linkClick,
      eventLabel: `${text} - ${link.href}`,
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.custom
    }
  });
};
