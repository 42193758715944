import { EventType, pushEvent } from "./index";

/**
 * File Download
 */
export const event49 = (e: MouseEvent) => {
  const link = (e.target as Element).closest("[data-analytics-event49]") as HTMLAnchorElement;
  if (!link) return;

  const text = link.textContent === "" ? link.getAttribute("aria-label") : link.textContent?.trim();
  const anchorLink = link.href;

  if (!text || !anchorLink) return;

  pushEvent({
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.downloads,
      eventLabel: `${anchorLink}`,
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.referral
    }
  });
};
